import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/globals.css';

import { Provider } from 'react-redux'
import { HeroUIProvider } from "@heroui/react";
import { WagmiConfig, createConfig } from 'wagmi'
import { createPublicClient, http } from 'viem'
import { bsc } from 'viem/chains';

import reportWebVitals from './reportWebVitals';
import BaseRouter from './router/index.jsx';
import store from './store'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_US from "./locales/en-US.json";
import zh_CN from "./locales/zh-CN.json";
import zh_TW from "./locales/zh-TW.json";

const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: bsc,
    transport: http('https://holy-dawn-wave.bsc.quiknode.pro/f7ef0447026e0bee6bce26b506b36c7ee45e94c2/')
  }),
})

i18n.use(initReactI18next).init({
  lng:
    localStorage.getItem("lang") == "en_US"
      ? "en_US"
      : (localStorage.getItem("lang") == "zh_TW"
        ? "zh_TW"
        : (localStorage.getItem("lang") == "ja" ? "ja" : (localStorage.getItem("lang") == "ko" ? "ko" : (localStorage.getItem("lang") == "es" ? "es" : (localStorage.getItem("lang") == "pt" ? "pt" : (localStorage.getItem("lang") == "de" ? "de" : (localStorage.getItem("lang") == "fr" ? "fr" : (localStorage.getItem("lang") == "th" ? "th" : "zh_CN")))))))),
  fallbackLng: "zh_CN",
  debug: true,
  resources: {
    en_US: {
      translation: en_US,
    },
    zh_CN: {
      translation: zh_CN,
    },
    zh_TW: {
      translation: zh_TW,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HeroUIProvider>
      <WagmiConfig config={config}>
        <Provider store={store}>
          <main className="dark text-foreground bg-background">
            <BaseRouter />
          </main>
        </Provider>
      </WagmiConfig>
    </HeroUIProvider>
  </React.StrictMode>
);

reportWebVitals();
export { store }